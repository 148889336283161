import React, { useEffect } from "react";

import { ApolloProvider } from "@apollo/client";
import { SessionProvider } from "next-auth/react";
import * as amplitude from "@amplitude/analytics-browser";

import { init } from "@/salesHelpers/sentry";

import { useApollo } from "../API/apolloClient";

type PageProps = {
	Component: React.Component<any, any>;
	pageProps: Record<string, unknown>;
	err: any;
};

import "../styles/global.scss";
import "../../polyfills";

init();

const App = ({ Component, pageProps, err }: PageProps) => {
	const apolloClient = useApollo(pageProps.initialApolloState);

	useEffect(() => {
		if (!window.location.href.includes("localhost")) {
			amplitude.init(process.env.NEXT_PUBLIC_AMP || "");
		}
	}, []);
	return (
		<SessionProvider>
			<ApolloProvider client={apolloClient}>
				{/* @ts-ignore */}
				<Component {...pageProps} err={err} />
			</ApolloProvider>
		</SessionProvider>
	);
};

// Disable as this is for next.js
// eslint-disable-next-line import/no-default-export
export default App;
